.workspace-container {
  margin-left: 85px;
  margin-right: 85px;
  /* padding: 8px; */
  margin-top: 50px;
  display: block;
  font-size: 16px;
  /* border: 1px solid red; */
  overflow: hidden;
  box-sizing: border-box;
}
.workspace-sec-one {
  display: flex;
  justify-content: space-between;
  width: 100%;
  /* border: 1px solid blue; */
}

.workspace-sec-one .alpha {
  width: 40%;
}

.alpha-header {
  font-size: 2.1em;
  margin-bottom: 50px;
  font-weight: 600;
}

.i-need-btn-container {
  display: flex;
}
.workspace-sec-one .workspace-sec-one-omega {
  width: 50%;
  border: 1px solid #ae76df;
  border-radius: 9px;
}

/* SECTION TWO */

.workspace-sec-two {
  /* border: 1px solid green; */
  margin-top: 120px;
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.workspace-sec-two .workspace-sec-two-alpha {
  /* border: 1px solid blue; */
  width: 50%;
}

.workspace-sec-two-alpha div {
  padding-top: 25px;
  padding-bottom: 25px;
}

.item-provided-left {
  padding-left: 35px;
}

.item-provided-right {
  padding-left: 55px;
}
.item-provided-icon-text {
  position: relative;
  font-size: 18px;
  top: -12px;
  left: 35px;
}

small {
  font-size: 16px;
  color: #444;
}

.workspace-sec-two .workspace-sec-two-omega-two {
  /* border: 1px solid rebeccapurple; */
  width: 49%;
  min-height: 450px;
  border-radius: 20px;
  padding: 40px;
  /* display: flex; */
}

.grey-bg {
  background: #f8f8f8;
}

.white-bg {
  background: #fff;
  border: 1px solid #444;

  /* color: #000; */
}
#workspace-team-header-holder {
  margin-bottom: 60px;
}

.workspace-get-your-team {
  font-weight: bold;
  font-size: 2.1em;
  word-spacing: 0.4px;
  line-height: 1.3;
  margin-bottom: 60px;
}

.workspace-get-your-team-paragraph {
  display: flex;
  text-align: left;
  /* border: 1px solid greenyellow; */
  width: 65%;
  font-size: 1.3em;
  color: #444;
  word-break: normal;
}
.workspace-team-header {
  font-size: 1.4em;
  font-weight: 300;
  text-transform: uppercase;
}

.empty-box {
  border: 1px solid #000;
  border-radius: 9px;
  background: #fff;
  text-transform: uppercase;
  outline: none;
  padding: 22px 75px !important;
  cursor: pointer;
  font-size: 12px;
}
#get-your-team {
  margin-left: 20px;
  width: 220px;
  position: relative;
  top: 20px;
}

.i-need-space-container {
  display: flex;
  /* border: 1px soild rebeccapurple; */
  margin-top: 60px;
}

.i-need-space-container-2 {
  display: flex;
  /* border: 1px soild rebeccapurple; */
  margin-top: 60px;
}
.i-need-space-container > div {
  position: relative;
  /* border: 1px solid greenyellow; */
  left: 51.5%;
  /* left: 90%; */
}

.i-need-space-container-2 > div {
  position: relative;
  /* border: 1px solid greenyellow; */
  left: 0%;
  /* left: 90%; */
}

@media screen and (max-width: 768px) {
  /* =======================================
SECTION ONE STYLE
======================================== */
  .workspace-container {
    /* box-sizing: border-box; */
    margin-left: 25px;
    margin-right: 25px;
    /* padding: 8px; */
    margin-top: 50px;
    font-size: 16px;
    /* border: 1px solid greenyellow; */
  }
  .workspace-sec-one {
    display: block;
    width: 100%;
  }

  .workspace-sec-one .alpha {
    width: 100%;
  }

  .alpha-header {
    font-size: 0.8em;
  }

  /* SECTION TWO */
  .workspace-sec-two {
    /* border: 1px solid red; */
    margin-top: 120px;
    width: 100%;
    display: block;
    /* justify-content: space-between; */
  }

  .workspace-sec-two .workspace-sec-two-alpha {
    /* border: 1px solid blue; */
    width: 100%;
  }

  .workspace-sec-two .workspace-sec-two-omega-two {
    /* border: 1px solid rebeccapurple; */
    width: 100%;
    min-height: 250px;
    border-radius: 20px;
    /* padding: 40px; */
    /* display: flex; */
  }

  .workspace-get-your-team {
    font-weight: bold;
    font-size: 1.3em;
    word-spacing: 0.4px;
    line-height: 1.3;
    margin-bottom: 60px;
  }

  .workspace-team-header {
    font-size: 1.4em;
    font-weight: 300;
    text-transform: uppercase;
  }

  .workspace-get-your-team-paragraph {
    /* display: flex; */
    /* text-align: left; */
    /* border: 1px solid greenyellow; */
    width: 100%;
    font-size: 0.8em;
    color: #444;
    word-break: normal;
  }
}
