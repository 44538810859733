/**
BuildcodeTogether Technologies
June 12, 2019. 12 : 52
Author : Ninja
*/

* {
  box-sizing: border-box;
}

body {
  font-size: 15px;
  /* font-family: "Montserrat", sans-serif; */
  font-family: "Cabin", sans-serif;
  margin: 0;
  /* background-color: black; */
  padding: 0;
}
