.landing-page {
  /* border: 1px solid orange; */
  height: 100vh;
  flex-direction: column;
  /* width: 100vw; */
  /* display: flex;
  align-items: center;
  justify-content: center; */
  overflow: hidden;
}

.landing-container {
  justify-content: flex-start;
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  /* border: 1px solid red; */
  margin-top: 10%;
}

.landing-page-footer {
  /* border: 1px solid red; */
  position: absolute;
  display: flex;
  padding: 2em 0;
  width: 90%;
  margin-left: 5%;
  /* justify-content: center; */
  /* width: 100vw; */

  justify-content: space-between;
  top: 88%;
}

.landing-page-footer span {
  font-size: 18px;
  text-transform: uppercase;
  font-weight: 600;
  color: #000;
  cursor: pointer;
}

.carousel-text {
  color: #aaa;
  margin-top: 10px;
  font-size: 2.14rem;
  line-height: 1;
  display: flex;
  margin-left: 5%;
  justify-content: flex-start;
}
#particles-js {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: -1;
}

.carousel-title {
  letter-spacing: 0.5rem;
  font-size: 4.7rem;
  font-weight: 600;
  margin-left: 05%;

  color: #000;
}

@media screen and (max-width: 760px) {
  .landing-page {
    /* border: 1px solid orange; */
    /* height: 40vh; */
    /* flex-direction: column;
    display: flex;
    align-items: center;
    justify-content: center; */
  }
  .landing-container {
    justify-content: flex-start;
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    height: 40%;
    /* padding-top: 10%; */
    /* border: 1px solid red; */
    margin-top: 20%;
  }

  .carousel-title {
    letter-spacing: 0.1rem;
    font-size: 6.5vw;
    font-weight: 600;
    color: #000;
    margin-left: 05%;
  }
  .carousel-text {
    color: #aaa;
    margin-top: 2%;
    font-size: 4vw;
    /* border: 1px solid red; */
    /* padding-left: 45px;
    padding-right: 5px; */
    /* margin-left: 10%; */
    line-height: 1.5;
    word-spacing: 0.3em;
    display: flex;
    justify-content: flex-start;
  }
  .landing-page-footer {
    top: 87%;
  }
  .landing-page-footer span {
    font-size: 4vw;
  }

  @media screen and (max-width: 460px) {
    .landing-container {
      justify-content: flex-start;
      align-items: flex-start;
      display: flex;
      flex-direction: column;
      height: 40%;
      /* padding-top: 10%; */
      /* border: 1px solid red; */
      margin-top: 40%;
    }

    .carousel-title {
      letter-spacing: 0.1rem;
      font-size: 8.5vw;
      font-weight: 600;
      color: #000;
      margin-left: 05%;
    }
    .carousel-text {
      color: #aaa;
      margin-top: 2%;
      font-size: 6vw;
      /* border: 1px solid red; */
      /* padding-left: 45px;
      padding-right: 5px; */
      margin-left: 20px;
      line-height: 1.5;
      word-spacing: 0.3em;
      display: flex;
      justify-content: flex-start;
    }
  }
}
