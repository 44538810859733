.topnav {
  overflow: hidden;
  background-color: transparent;
  /* padding: 10px 80px; */
  /* border-radius: 0 0 20px 20px; */
  /* box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.05); */
  /* position: fixed; */
  z-index: 1000;
  /* max-width: 100%; */
  /* width: 100%; */
  /* top: 0px; */
  max-width: 100%;
  /* display: none; */
}

.mobile-menu {
  overflow: hidden;
  background-color: #333;
  position: relative;
}

.mobile-menu a {
  color: white;
  padding: 14px 16px;
  text-decoration: none;
  font-size: 17px;
  display: block;
}

.mobile-menu a:hover {
  background-color: #ddd;
  color: black;
}

.topnav a {
  float: left;
  display: flex;
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
  font-size: 16px;
  letter-spacing: 0.2px;
  line-height: 1.3;
  text-transform: capitalize;
  font-weight: 500;
  font-family: inherit;
  color: #000;
}
/* .topnav a:hover {
  color: #000;
} */

.logo-image {
  background-image: url("../../assets/logo.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  width: 60px;

  height: 60px;
  margin-left: 40px;
}

.topnav a.icon {
  font-size: 35px;

  position: relative;
  right: 30%;
  /* display: none; */
}

.icon-style {
  margin-right: 6px;
}

@media screen and (max-width: 768px) {
  .topnav {
    /* overflow: hidden;
    background-color: #f5f5f5; */
    /* padding: 10px 20px; */
    /* position: fixed; */
    /* z-index: 1000; */
    /* max-width: 100%; */
    width: 100%;
    max-width: 100%;
  }

  .topnav a:not(:first-child) {
    display: none;
  }
  a.consulting {
    display: none;
  }
  .topnav a.icon {
    float: right;
    position: relative;
    right: -1px;
    display: block;
    font-size: 40px;
  }

  .logo-image {
    margin-left: 0px;
    width: 45px;
    height: 45px;
  }
}
